import React, { Component } from 'react';
import infeedo_logo from '../../../static/images/infeedo.svg';
import config from '../../../config';
import axios from '../../helper/Axios';
import classnames from 'classnames';
import {looseFocus} from '../../helper/looseFocus';
import { NotificationManager } from 'react-notifications';
import "../../css/accordian.css";
import OtpModal from './OtpModal';
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemPanel, AccordionItemButton } from 'react-accessible-accordion';
import { getServiceRegion } from '../../helper/getApiBase';
import handleException from '../../helper/errorHandling';

export default class AnonymousBat extends Component {

    state = {
        email: '',
        loading: false,
        otp: '',
        is_active: false,
        session_id: '',
        validating: false,
        attempt_number: 0,
        resend_flag: false
    }

    sendOTP = async (e) => {
        const { email } = this.state;
        if(e){
            e.preventDefault();
        }
        looseFocus();
        this.setState({loading: true});
        this.setTimerForModal();
        try {
            sessionStorage.removeItem("current_region_url");
            await getServiceRegion(email,"dashboard");
            const response = await axios.post('/v1/conversations/generate_otp?is_accessible=true', {email})
            if(response.status === 200){
                this.setState({session_id: response.data.session_id});
                NotificationManager.success(`We have sent you the OTP on ${email}. Please check your email.`)
                this.openOTPModal();
            }
        } catch (error) {
            handleException(error, 'Reset Failed');
        }
        this.setState({loading: false});
    }

    increaseCount = () => {
        const { attempt_number } = this.state;
        this.setState({attempt_number: attempt_number + 1});
    }

    hideResendAndSendOTP = () => {
        this.setState({resend_flag: true});
        this.sendOTP();
    }

    openOTPModal = () => {
        this.setState({
            is_active: true
        });
    }

    setTimerForModal = () =>{
        this.setState({
            date_now : Date.now()
        });
    }

    closeOTPModal = () => {
        this.setState({is_active: false, attempt_number: 0, resend_flag: false, otp: ''});
    }

    onOtpSubmit = async (otp) => {
        const { session_id, attempt_number } = this.state;
        this.setState({validating: true});
        looseFocus();
        try {
            const response = await axios.get('/v1/conversations/validate_otp?is_accessible=true', {params: {otp, session_id}})
            if(response.status === 200){
                window.location.href = `${config.new_chat_url}/auth/${response.data.token}/anonymous`
            }
        } catch (error) {
            handleException(error, 'OTP verification Failed');
        }
        this.setState({validating: false, attempt_number: attempt_number + 1}, () => this.checkAttemptNumber());
        
    }

    checkAttemptNumber = () => {
        const { attempt_number } = this.state;
        if(attempt_number === 3){
            this.closeOTPModal();
            NotificationManager.info('Oops you have exhausted all the attempts to verify your identity. Please try again.');
        }
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    render() {
        const { email, loading, otp, is_active, validating, attempt_number, resend_flag , date_now} = this.state;
        return (
            <div className="column is-5 mar-auto pd-25">
                <figure className="app-logo">
                    <img src={infeedo_logo} width="130px" alt="amber logo"/>
                </figure>
                <div className="columns is-mobile">
                    <div className="column no-pd-bottom"><h1 className="login-heading no-margin-padding-bottom">Send messages directly to<br/> leadership</h1></div>
                    {/* <div className="column is-2 no-pd-bottom"><img src={bat} width="60px" alt="bat logo" className="pd-top-15"/></div> */}
                </div>
                <p className="font-14">This is a place for directly communicating with your leadership about suggestions, ideas or things that are a concern for you at the workplace.</p>
                <br/>
                <form onSubmit={this.sendOTP}>
                    <div className="field">
                        <p className="control">
                            <input onChange={this.onChange} value={email} name="email" className="input" type="email" placeholder="Enter Work Email ID" id="email" required autoFocus/>
                        </p>
                    </div>
                    <div className="field columns is-mobile pd-top-12">
                        <label className="checkbox column is-6 font-12">
                            {/* <input type="checkbox"/>
                            &nbsp;Remember me */}
                        </label>
                        <p className="control has-text-right column is-6">
                            <button type="submit" className={classnames('button is-link font-14', {'is-loading': loading})} disabled={!email.length}>
                            &nbsp;&nbsp;Send Login OTP&nbsp;&nbsp;
                            </button>
                        </p>
                    </div> 
                </form>
                <div>
                    <br/>
                    <br/>
                    <h1 className="login-heading">FAQ's</h1>
                    <div className="font-14">
                    <Accordion allowZeroExpanded={true}>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Why do you need my email ID if this is anonymous?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                Anonymous Bat allows anonymous conversations between you and the people who have access to your messages. The only reason we collect your email ID is to make sure it’s you who is sending these messages and not someone else on your behalf. Names are pseudo-anonymised to names like Happy Crab, Smiling Cat etc to hide your identity. <b>However, you will be able to see the names of people making this a safe and secure place for you! </b>
                                <br/><br/>You can reach out to us at <a href="mailto:help@infeedo.com">help@infeedo.com</a> if you have questions.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Is this really anonymous or can someone get a backend access?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                Yes, “Anonymous Bat” is 100% anonymous where nobody will be able to see your name. Additionally, inFeedo (the team behind Amber) as a third-party organization also confirms that your employer cannot see the names behind any feedback shared with Anonymous Bat. You’re our priority and your name will never be displayed, unless legally obliged to do so.
                                <br/><br/>You can reach out to us at <a href="mailto:help@infeedo.com">help@infeedo.com</a> if you have questions.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Why should I share sensitive information?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                One reason people don’t report workplace harassment, discrimination or misbehavior is they’re uncomfortable talking to people about it. Because Amber is not a human, she can’t judge or assess you. In fact admins may reply to your message and you may engage in a 2 way conversation via this platform. <b>What’s even cooler is that you can see their names but they can’t!</b>
                                <br/><br/>You can reach out to us at <a href="mailto:help@infeedo.com">help@infeedo.com</a> if you have questions.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                    </div>
                    <br/>
                    <br/>
                </div>
                <OtpModal date_now={date_now} is_active={is_active} onOtpSubmit={this.onOtpSubmit} onClose={this.closeOTPModal} otp={otp} validating={validating} sendOTP={this.hideResendAndSendOTP} attempt_number={attempt_number} resend_flag={resend_flag}/>
            </div>
        )
    }
}
